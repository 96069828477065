import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import {baseQuery, baseQueryWithReauth} from "types/api/base-query";
import {
  PostCourseProps,
  CourseResponse,
  DeleteCourseProps,
  GetListCourseProps,
} from "types/api/course/get-course";

const {POST, PUT, DELETE} = API_METHOD;

export const RESUME_API_REDUCER_KEY = "courseApi";

export const courseApi = createApi({
  reducerPath: RESUME_API_REDUCER_KEY,
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Post"],
  endpoints: builder => ({
    course: builder.mutation<CourseResponse, PostCourseProps>({
      query: data => ({
        url: apiPath.CREATE_COURSE,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    getCourse: builder.query<CourseResponse, GetListCourseProps | null>({
      query: data => ({
        url: `${apiPath.GET_COURSE}${serialize(data)}`,
      }),
      providesTags: ["Post"],
    }),
    updateCourse: builder.mutation<CourseResponse, PostCourseProps>({
      query: data => ({
        url: apiPath.PUT_COURSE,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    deleteCourseById: builder.mutation<void, DeleteCourseProps>({
      query: id => ({
        url: `${apiPath.DELETE_COURSE}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const courseApiReducer = courseApi.reducer;

export const {
  useCourseMutation,
  useGetCourseQuery,
  useDeleteCourseByIdMutation,
  useUpdateCourseMutation,
} = courseApi;
