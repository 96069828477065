import {FC, useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import checkToken from "utils/check-token";
import {USER_ROUTE} from "./routers";
import {useDispatch} from "react-redux";
import {candidateActions} from "store/auth/candidate";
import {handleRefreshToken} from "types/api/base-query";

const ProtectedOutletCandidate: FC = () => {
  const accessToken = localStorage.getItem("accessTokenCandicate");
  const navigate = useNavigate();
  const [access, setAccess] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleToken = async () => {
      try {
        const isValidToken = checkToken(accessToken);

        if (isValidToken) {
          setAccess(isValidToken);
        } else {
          const isSuccessRefreshToken = await handleRefreshToken();
          if (!isSuccessRefreshToken) {
            dispatch(candidateActions.signOut());
          }
        }
      } catch (error) {
        // setAccess(false);
      }
    };

    handleToken();
    // console.log(access);
  }, [accessToken]);

  useEffect(() => {
    if (!access) {
      navigate(USER_ROUTE.HOME);
    }
  }, [access, navigate]);

  return <Outlet />;
};

export default ProtectedOutletCandidate;
