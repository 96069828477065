import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { candidateAPI } from "store/api/candidate";
import { SignInWithEmailAndPasswordResponse } from "types/api/auth/sign-in-with-email-and-password";
import { CandidateSliceProps } from "types/slices/candidate";

export const candidateInitialState: CandidateSliceProps = {
  accessToken: null,
  data: {
    id: 0,
    username: "",
    email: "",
    password: null,
    phoneNumber: "",
    fullName: "",
    studiedAtSec: false,
    isLocked: false,
    roles: [],
    accessToken: "",
  },
  isAuthenticated: false,
};

export const candidateSlice = createSlice({
  name: "candidate",

  initialState: candidateInitialState,

  reducers: {
    signOut: () => {
      return {
        ...candidateInitialState,
        isAuthenticated: false,
        accessToken: null,
        accessTokenUser: null,
      };
    },
  },

  extraReducers: (builder) => {
    /**thí sinh */
    builder.addMatcher(
      candidateAPI.endpoints.signInCandidate.matchFulfilled,
      (state, action: PayloadAction<SignInWithEmailAndPasswordResponse>) => {
        state.accessToken = action.payload.accessToken;
        localStorage.setItem(
          "accessTokenCandicate",
          action.payload.accessToken
        );
        localStorage.setItem(
          "refreshTokenCandicate",
          action.payload.refreshToken
        );
        state.data = action.payload;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      candidateAPI.endpoints.signInCandidate.matchRejected,
      (state) => {
        state.accessToken = null;
        state.isAuthenticated = false;
      }
    );

    builder.addMatcher(
      candidateAPI.endpoints.signUpCandidate.matchFulfilled,
      (state, action: PayloadAction<SignInWithEmailAndPasswordResponse>) => {
        state.accessToken = action.payload.accessToken;
        localStorage.setItem(
          "accessTokenCandicate",
          action.payload.accessToken
        );
        localStorage.setItem(
          "refreshTokenCandiate",
          action.payload.refreshToken
        );
        state.data = action.payload;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      candidateAPI.endpoints.signUpCandidate.matchRejected,
      (state) => {
        state.accessToken = null;
        state.isAuthenticated = false;
      }
    );
  },
});

export const selectCandidate = ({
  candidate,
}: {
  candidate: CandidateSliceProps;
}) => candidate;

export const selectCandidateData = ({
  candidate,
}: {
  candidate: CandidateSliceProps;
}) => candidate?.data;

export const selectUserRoles = ({
  candidate,
}: {
  candidate: CandidateSliceProps;
}) => candidate?.data?.roles;

export const candidateActions = candidateSlice.actions;

export const candidateReducer = persistReducer(
  {
    key: "rtk:candidate",
    storage,
    whitelist: ["accessTokenCandidate"],
  },
  candidateSlice.reducer
);
