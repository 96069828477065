import "./HeaderPage.scss";
import { Avatar, Button, Col, Dropdown, Row, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { USER_ROUTE } from "routers/routers";
import { useRef } from "react";
import ModalLoginCandidate from "pages/UserPage/ModalRegister/ModalLoginCandidate";
import {
  FileTextFilled,
  KeyOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import logo from "assets/images/logo.png";
import { MenuProps } from "antd/lib";
import {
  candidateActions,
  selectCandidate,
  selectCandidateData,
} from "store/auth/candidate";
import ModalChagnePassword from "components/ModalChagnePassword";
import ModalCustom, {
  ModalCustomHandle,
} from "components/ModalCustom/ModalCustom";
import { setTypeLogin } from "store/global/global.slice";

const HeaderPageUser = (props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { fullName } = useAppSelector(selectCandidateData);
  const { accessToken } = useAppSelector(selectCandidate);

  const registerRef = useRef<any>();
  const loginRef = useRef<any>();
  const changePassword = useRef<any>();
  const forgotPassword = useRef<ModalCustomHandle>(null);

  const items: any["items"] = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            dispatch(candidateActions.signOut());
            // localStorage.clear();
            // sessionStorage.clear();
          }}
        >
          Đăng xuất
        </a>
      ),
      icon: <LogoutOutlined />,
    },
    {
      key: "2",
      label: (
        <a onClick={() => navigate(USER_ROUTE.GRADE_EXAM_LIST)}>
          Lịch sử bài thi
        </a>
      ),
      icon: <FileTextFilled />,
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            changePassword.current.open();
            changePassword.current.resetField();
          }}
        >
          Đổi mật khẩu
        </a>
      ),
      icon: <KeyOutlined />,
    },
    {
      key: "4",
      label: (
        <a
          onClick={() => {
            forgotPassword.current?.openModal();
          }}
        >
          Quên mật khẩu
        </a>
      ),
      icon: <LockOutlined />,
    },
  ];

  const itemsLogin: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="default"
          onClick={() => {
            dispatch(setTypeLogin("REGISTER"));
            registerRef.current.open();
          }}
          icon={<i className="fa-solid fa-pen"></i>}
          style={{ width: "100%" }}
          className="btn-animation"
        >
          Đăng ký
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="btn-animation"
          type="default"
          onClick={() => {
            dispatch(setTypeLogin("LOGIN"));
            loginRef.current.open();
          }}
          icon={<i className="fa-solid fa-user"></i>}
        >
          Đăng nhập
        </Button>
      ),
    },
  ];

  return (
    <div className="header-page-user">
      {/* Trang chủ */}
      <Row
        style={{
          width: "100%",
        }}
        justify={"space-between"}
      >
        <div className="img-logo">
          <img src={logo} onClick={() => navigate(USER_ROUTE.HOME)} />
        </div>

        <div className="slogan-text">SIMPLE ENGLISH CENTER</div>

        <div className="button-login">
          {!accessToken ? (
            <>
              <Row gutter={16} className="btn-laptop">
                <Col>
                  <Button
                    className="btn-animation"
                    type="default"
                    onClick={() => {
                      dispatch(setTypeLogin("REGISTER"));
                      registerRef.current.open();
                    }}
                    icon={<i className="fa-solid fa-pen"></i>}
                  >
                    Đăng ký
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="default"
                    className="btn-animation"
                    onClick={() => {
                      dispatch(setTypeLogin("LOGIN"));
                      loginRef.current.open();
                    }}
                    icon={<i className="fa-solid fa-user"></i>}
                  >
                    Đăng nhập
                  </Button>
                </Col>
              </Row>
              <Dropdown
                className="dropdown-mobile"
                arrow={true}
                menu={{ items: itemsLogin }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <MenuOutlined />
              </Dropdown>
            </>
          ) : (
            <div className="username">
              <Typography.Text strong className="username-text">{`${
                fullName ?? "G"
              } `}</Typography.Text>
              <Dropdown
                arrow={true}
                menu={{ items }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <Avatar className="avatar-user" shape="square">
                  {fullName?.charAt(0).toLocaleUpperCase() ?? "GUEST"}
                </Avatar>
              </Dropdown>
            </div>
          )}
        </div>
      </Row>
      <ModalLoginCandidate ref={registerRef} />
      <ModalLoginCandidate ref={loginRef} />
      <ModalChagnePassword ref={changePassword} type="USER" />
      <ModalCustom
        ref={forgotPassword}
        title="Quên mật khẩu"
        width={"30%"}
        footer={[
          <Button
            key="back"
            onClick={() => forgotPassword.current?.closeModal()}
            danger
          >
            Thoát
          </Button>,
        ]}
      >
        <h3 style={{ padding: "0 20px" }}>
          Vui lòng liên hệ số hotline <a href="#">18001998</a> hoặc{" "}
          <a
            href="https://zalo.me/0352335336"
            target="_blank"
            rel="noopener noreferrer"
          >
            Liên hệ Zalo
          </a>{" "}
          để được cấp mật khẩu mới!
        </h3>
      </ModalCustom>
    </div>
  );
};
export default HeaderPageUser;
