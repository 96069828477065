import {FC, useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {USER_ROUTE} from "./routers";
import {useAppSelector} from "hooks";
import {selectUserRoles} from "store/auth/user";

const RoleOutlet: FC<any> = props => {
  const {listRoleRequired} = props;
  const userRoles = useAppSelector(selectUserRoles);
  const navigate = useNavigate();
  const [access, setAccess] = useState(true);

  useEffect(() => {
    try {
      const isValidRole = listRoleRequired?.some(e => userRoles.includes(e));
      setAccess(isValidRole);
    } catch (error) {
      console.log(error);
      // setAccess(false);
    }
    // console.log(access);
  }, [userRoles,listRoleRequired]);

  useEffect(() => {
    if (!access) {
      navigate(USER_ROUTE.HOME);
    }
  }, [access,navigate]);

  return <Outlet />;
};

export default RoleOutlet;
