import { Form, Row, Space, Tabs, Tag } from "antd";
import InputFields from "components/InputFields/InputFields";
import ModalCustom, {
  ModalCustomHandle,
} from "components/ModalCustom/ModalCustom";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { loginForm, registerForm } from "./config";
import { useNavigate } from "react-router-dom";
import { Notification } from "components";
import { BulbOutlined } from "@ant-design/icons";
import {
  useSignInCandidateMutation,
  useSignUpCandidateMutation,
} from "store/api/candidate";
import { CustomError } from "types/api/base-query";
import { TabsProps } from "antd/lib";
import { useAppDispatch, useAppSelector } from "hooks";
import { setTypeLogin } from "store/global/global.slice";
import "./style.scss";

const items: TabsProps["items"] = [
  {
    key: "2",
    label: "Đăng ký".toLocaleUpperCase(),
  },
  {
    key: "1",
    label: "Đăng nhập".toLocaleUpperCase(),
  },
];
interface Props {
  // type: "REGISTER" | "LOGIN";
  nextToPage?: string;
}

const ModalLoginCandidate = forwardRef((props: Props, ref) => {
  const { nextToPage } = props;
  const type = useAppSelector((e) => e.global.typeLogin);
  const typeTests = useAppSelector((e) => e.global.typeTests);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const loginCandidateRef = useRef<ModalCustomHandle>(null);
  const [fields, setFields] = useState(loginForm);
  const [form] = Form.useForm();
  const [signInCandidate, { error, isSuccess, isLoading }] =
    useSignInCandidateMutation();

  useEffect(() => {
    if (typeTests) {
      const dataRegister = registerForm.map((el) => {
        if (el.name === "typeTest") {
          return {
            ...el,
            options: typeTests,
          };
        } else return { ...el };
      });
      setFields(dataRegister);
    }
  }, [typeTests, type]);

  useEffect(() => {
    if (type === "LOGIN") {
      setFields(loginForm);
    }
    // if (type === "REGISTER") {
    //   const dataRegister = registerForm.map((el) => {
    //     if (el.name === "typeTest") {
    //       return {
    //         ...el,
    //         options: typeTests,
    //       };
    //     } else return { ...el };
    //   });
    //   setFields(dataRegister);
    // }
  }, [type]);

  const [
    signUpCandidate,
    {
      error: errorSignUp,
      isSuccess: isSuccessSignUp,
      isLoading: isLoadingSignUp,
    },
  ] = useSignUpCandidateMutation();

  const handleLogin = async () => {
    try {
      const value = await form.validateFields();
      if (type === "LOGIN") {
        await signInCandidate(value);
      } else await signUpCandidate(value);
      if (nextToPage) {
        navigate(nextToPage);
      }
    } catch (error) {}
  };

  useImperativeHandle(ref, () => ({
    open: () => loginCandidateRef.current?.openModal(),
  }));

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [type, form]);
  useEffect(() => {
    const errSignIn = error as CustomError;
    const errSignUp = errorSignUp as CustomError;

    if (errSignIn && errSignIn?.status === 401) {
      return Notification({
        message:
          errSignIn?.data?.message ||
          errSignIn?.data ||
          "Bạn không có quyền truy cập!",
        status: "error",
      });
    }

    if (errSignUp || errSignIn) {
      return Notification({
        status: "error",
        message:
          errSignIn?.data?.message ||
          errSignIn?.data ||
          errSignUp?.data ||
          "Kết nối thất bại",
      });
    }
  }, [error, errorSignUp]);

  useEffect(() => {
    if (isSuccess || isSuccessSignUp) {
      Notification({
        status: "success",
        message: `${type === "LOGIN" ? "Đăng nhập" : "Đăng kí"}  thành công`,
      });
      loginCandidateRef.current?.closeModal();
    }
  }, [isSuccess, isSuccessSignUp, type]);

  const handleChangeTabs = (key: string) => {
    if (key === "1") {
      dispatch(setTypeLogin("LOGIN"));
      setFields(loginForm);
    }
    if (key === "2") {
      dispatch(setTypeLogin("REGISTER"));
      // const dataRegister = registerForm.map((el) => {
      //   if (el.name === "typeTest") {
      //     return {
      //       ...el,
      //       options: typeTests,
      //     };
      //   } else return { ...el };
      // });
      // setFields(dataRegister);
    }
  };

  return (
    <div className="login-candidate" onKeyDown={(e) => handleKeyDown(e)}>
      <ModalCustom
        ref={loginCandidateRef}
        onOk={handleLogin}
        title={`${
          type === "LOGIN" ? "Đăng nhập" : "Đăng ký thông tin"
        } thi thử trực tuyến`}
        okText={
          type === "LOGIN"
            ? "Đăng nhập".toLocaleUpperCase()
            : "Đăng ký".toLocaleUpperCase()
        }
        cancelText="HUỶ BỎ"
        loading={isLoading || isLoadingSignUp}
        width={"50%"}
      >
        <Tabs
          items={items}
          style={{ justifyContent: "center" }}
          onChange={handleChangeTabs}
          activeKey={type === "LOGIN" ? "1" : "2"}
        />
        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
          <Form form={form}>
            <Row gutter={[16, 8]}>
              <InputFields data={fields} />
            </Row>
          </Form>
          {type === "REGISTER" && (
            <Tag
              color="gold"
              icon={<BulbOutlined />}
              style={{
                fontSize: "12px",
              }}
              className="custom-tag"
            >
              Lưu ý: Bạn phải điền đúng SĐT và Email để nhận được kết quả thi.
            </Tag>
          )}
        </Space>
      </ModalCustom>
    </div>
  );
});

export default ModalLoginCandidate;
