const PUT_GRADING_ESSAY = "/exam/grading/tu_luan";
const PUT_COMMENT_GRADING_ESSAY = "/exam/comment";
const GET_LIST_USER_GRADING = "/exam/find_user";
const GET_LIST_EXAM_GRADING = "/exam/paging_search";
const GET_DETAIL_EXAM_GRADING = "/exam/by_id";
const DELETE_USER_GRADING = "/user/delete";
const EXPORT_LIST_USER_GRADING = "/exam/export/find_user";
export const gradingEssay = {
  PUT_GRADING_ESSAY,
  GET_LIST_USER_GRADING,
  GET_DETAIL_EXAM_GRADING,
  GET_LIST_EXAM_GRADING,
  PUT_COMMENT_GRADING_ESSAY,
  DELETE_USER_GRADING,
  EXPORT_LIST_USER_GRADING,
};
