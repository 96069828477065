import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import { toQueryString} from "helper/parse";
import {
  baseQueryCandicateWithReauth,
} from "types/api/base-query-candidate";
import {AllQuestionResponse, GetAllQuestionProps} from "types/api/question";
export const QUESTION_API_REDUCER_KEY = "questionInExamApi";

export const questionInExamApi = createApi({
  reducerPath: QUESTION_API_REDUCER_KEY,
  baseQuery: baseQueryCandicateWithReauth,
  tagTypes: ["Post"],
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getAllQuestionByParams: builder.query<
      AllQuestionResponse,
      GetAllQuestionProps
    >({
      query: data => ({
        url: `${apiPath.GET_ALL_QUESTION}?${toQueryString(data)}`,
      }),
      providesTags: ["Post"],
    }),
  }),
});

export const questionInExamApiReducer = questionInExamApi.reducer;

export const {useGetAllQuestionByParamsQuery} = questionInExamApi;
