import { FIELD_TYPE } from "types/enums/field-type";
import { DataFormField } from "types/models/input-field-type";

const changePasswordFields: DataFormField[] = [
  {
    label: "Tài khoản",
    name: "username",
    type: FIELD_TYPE.INPUT,
    rules: [
      {
        required: true,
        message: "Bắt buộc!",
      },
    ],
    disabled: true,
    col: 1,
  },
  {
    label: "Mật khẩu cũ",
    name: "password",
    type: FIELD_TYPE.INPUT_PASSWORD,
    rules: [
      {
        required: true,
        message: "Bắt buộc!",
      },
    ],
    col: 1,
  },
  {
    label: "Mật khẩu mới",
    name: "newPassword",
    type: FIELD_TYPE.INPUT_PASSWORD,
    rules: [
      {
        required: true,
        message: "Bắt buộc!",
      },
    ],
    col: 1,
  },
];

export default changePasswordFields;
