import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import {baseQuery, baseQueryWithReauth} from "types/api/base-query";
import {
  TopicProps,
  TopicSubmitProps,
  TopicsResponse,
} from "types/api/course/topic";

const {POST, PUT, DELETE} = API_METHOD;

export const EXAM_API_REDUCER_KEY = "topicApi";

export const topicApi = createApi({
  reducerPath: EXAM_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Post"],
  endpoints: builder => ({
    topic: builder.mutation<TopicsResponse, TopicSubmitProps>({
      query: data => ({
        url: apiPath.POST_TOPIC,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    getListTopic: builder.query<TopicsResponse, null>({
      query: data => ({
        url: apiPath.GET_LIST_TOPIC,
      }),
      providesTags: ["Post"],
    }),
    updateTopic: builder.mutation<TopicsResponse, TopicSubmitProps>({
      query: data => ({
        url: apiPath.PUT_TOPIC,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    getListTopicByParams: builder.query<TopicsResponse, TopicProps>({
      query: data => ({
        url: `${apiPath.GET_LIST_TOPIC}${serialize(data)}`,
      }),
      providesTags: ["Post"],
    }),
    deleteTopicById: builder.mutation<void, TopicProps>({
      query: id => ({
        url: `${apiPath.DELETE_TOPIC}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const topicApiReducer = topicApi.reducer;

export const {
  useTopicMutation,
  useGetListTopicQuery,
  useUpdateTopicMutation,
  useGetListTopicByParamsQuery,
  useDeleteTopicByIdMutation,
} = topicApi;
