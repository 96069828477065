import { combineReducers, configureStore } from "@reduxjs/toolkit";

import globalSliceReducer from "./global/global.slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authAPI, authAPIReducer } from "./api/auth";
import { userReducer, userSlice } from "./auth/user";
import { adminReducer, adminSlice } from "./admin/admin";
import { courseApi, courseApiReducer } from "./api/course";
import { examApi, examApiReducer } from "./api/exam";
import { topicApi, topicApiReducer } from "./api/topic";
import { partApi, partApiReducer } from "./api/part";
import { fileApi, fileApiReducer } from "./api/file";
import { globalSlice } from "./global/global.slice";
import { detailExamReducer, detailExamSlice } from "./slice/detail-exam";
import { questionApi, questionApiReducer } from "./api/question";
import { detailPartReducer, detailPartSlice } from "./slice/detail-part";
import { gradingApi, gradingReducer } from "./api/grading";
import { gradingEssayApi, gradingEssayReducer } from "./api/grading-essay";
import { gradeEssayReducer, gradeEssaySlice } from "./gradeEssay";
import { resultExamReducer, resultExamSlice } from "./slice/result-exam";
import {
  detailResultQuestionApi,
  detailResultQuestionReducer,
} from "./api/detail-result-question";
import { candidateAPI, candidateAPIReducer } from "./api/candidate";
import { candidateReducer, candidateSlice } from "./auth/candidate";
import { activeUserApi, activeUserApiReducer } from "./api/active-password";
import {
  questionInExamApi,
  questionInExamApiReducer,
} from "./api/question_in_exam";
import { userApi, userApiReducer } from "./api/user";
import {
  changePasswordAPI,
  changePasswordAPIReducer,
} from "./api/changePassword";
import {
  changePasswordAdminAPI,
  changePasswordAdminAPIReducer,
} from "./api/changePasswordadmin";
import {
  forgotPasswordAdminAPI,
  forgotPasswordAdminAPIReducer,
} from "./api/forgotPasswordadmin";
import {
  fileCandidateApi,
  fileCandidateApiReducer,
} from "./api/file-candidate";

const rootStore = {
  [authAPI.reducerPath]: authAPIReducer,
  [courseApi.reducerPath]: courseApiReducer,
  [examApi.reducerPath]: examApiReducer,
  [topicApi.reducerPath]: topicApiReducer,
  [partApi.reducerPath]: partApiReducer,
  [questionApi.reducerPath]: questionApiReducer,
  [fileApi.reducerPath]: fileApiReducer,
  [userApi.reducerPath]: userApiReducer,
  [userSlice.name]: userReducer,
  [adminSlice.name]: adminReducer,
  [globalSlice.name]: globalSliceReducer,
  [detailPartSlice.name]: detailPartReducer,
  [detailExamSlice.name]: detailExamReducer,
  [gradingApi.reducerPath]: gradingReducer,
  [gradingEssayApi.reducerPath]: gradingEssayReducer,
  [gradeEssaySlice.name]: gradeEssayReducer,
  [resultExamSlice.name]: resultExamReducer,
  [detailResultQuestionApi.reducerPath]: detailResultQuestionReducer,
  [candidateAPI.reducerPath]: candidateAPIReducer,
  [candidateSlice.name]: candidateReducer,
  [activeUserApi.reducerPath]: activeUserApiReducer,
  [questionInExamApi.reducerPath]: questionInExamApiReducer,
  [changePasswordAPI.reducerPath]: changePasswordAPIReducer,
  [changePasswordAdminAPI.reducerPath]: changePasswordAdminAPIReducer,
  [forgotPasswordAdminAPI.reducerPath]: forgotPasswordAdminAPIReducer,
  [fileCandidateApi.reducerPath]: fileCandidateApiReducer,
};

// const rootReducer = combineReducers({
//   ...rootStore,
// });

const appReducer = combineReducers({
  ...rootStore,
});

const rootReducer = (state, action) => {
  if (action.type === "candidate/signOut") {
    storage.removeItem("persist:root");
    localStorage.removeItem("accessTokenCandicate");
    localStorage.removeItem("refreshTokenCandicate");
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authAPI.middleware,
      courseApi.middleware,
      examApi.middleware,
      topicApi.middleware,
      partApi.middleware,
      questionApi.middleware,
      fileApi.middleware,
      gradingApi.middleware,
      detailResultQuestionApi.middleware,
      candidateAPI.middleware,
      gradingEssayApi.middleware,
      activeUserApi.middleware,
      questionInExamApi.middleware,
      userApi.middleware,
      changePasswordAPI.middleware,
      changePasswordAdminAPI.middleware,
      forgotPasswordAdminAPI.middleware,
      fileCandidateApi.middleware,
    ]),
  devTools: process.env.REACT_NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
