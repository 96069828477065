import "./HeaderPage.scss";
import { Avatar, Button, Dropdown, Image, Row } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { ROUTER_ROUTE } from "routers/routers";
import { setCollapsed } from "store/global/global.slice";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect, useRef } from "react";
import {
  KeyOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { selectUserData, userActions } from "store/auth/user";
import ModalChagnePassword from "components/ModalChagnePassword";
import { toArray } from "helper/parse";
import { ROLE } from "types/enums/role";
import { useForgotPasswordAdminMutation } from "store/api/forgotPasswordadmin";
import { Notification } from "components/Notification/notification";
const HeaderPage = (props) => {
  const { username, roles, email } = useAppSelector(selectUserData);

  const [forgotPasswordAdmin, { data, isSuccess, isError }] =
    useForgotPasswordAdminMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const collapsed = useSelector((state: RootState) => state.global.collapsed);
  useEffect(() => {
    dispatch(setCollapsed(true));
  }, []);
  const changePassword = useRef<any>();

  const items: any["items"] = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            dispatch(userActions.signOut());
            navigate(ROUTER_ROUTE.LOGIN);
          }}
        >
          Đăng xuất
        </a>
      ),
      icon: <LogoutOutlined />,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            changePassword.current.open();
            changePassword.current.resetField();
          }}
        >
          Đổi mật khẩu
        </a>
      ),
      icon: <KeyOutlined />,
    },
    {
      key: "3",
      label: toArray(roles).includes(ROLE.ROLE_ADMIN) ? (
        <div
          onClick={() => {
            forgotPasswordAdmin({ email: email });
          }}
          style={{ cursor: "pointer" }}
        >
          Quên mật khẩu
        </div>
      ) : (
        <div style={{ cursor: "not-allowed", color: "grey" }}>
          Quên mật khẩu
        </div>
      ),
      icon: <LockOutlined />,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      Notification({
        status: "success",
        message: data?.message,
      });
    }
  }, [isSuccess]);
  return (
    <div className="header-page">
      {/* Trang chủ */}
      <Row justify={"space-between"} style={{ width: "100%" }}>
        <div className="collapsed-btn">
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              dispatch(setCollapsed(!collapsed));
            }}
            icon={<MenuOutlined />}
            size="large"
          />
        </div>
        <div className="header-logo">
          <Image
            src={Logo}
            alt="logo"
            height="50px"
            width={"150px"}
            preview={false}
          />
          {/* <p>CHINH PHỤC TIÊNG ANH VỚI 3 CÔNG THỨC</p> */}
        </div>
        <div className="button-login">
          <Dropdown
            arrow={true}
            menu={{ items }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <Avatar className="avatar-user" shape="square">
              {username?.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </Dropdown>
        </div>
      </Row>
      <ModalChagnePassword ref={changePassword} type="ADMIN" />
    </div>
  );
};
export default HeaderPage;
