import { Form, Row, Space } from "antd";
import InputFields from "components/InputFields/InputFields";
import ModalCustom, {
  ModalCustomHandle,
} from "components/ModalCustom/ModalCustom";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useChangePasswordMutation } from "store/api/changePassword";
import changePasswordFields from "./config";
import { useAppSelector } from "hooks";
import { selectCandidate, selectCandidateData } from "store/auth/candidate";
import { Notification } from "components/Notification/notification";
import { CustomError } from "types/api/base-query";
import { selectUserData } from "store/auth/user";
import { ROLE } from "types/enums/role";

interface Props {
  type: "ADMIN" | "USER";
}

const ModalChagnePassword = forwardRef((props: Props, ref) => {
  const { type } = props;
  const changePasword = useRef<ModalCustomHandle>(null);
  const { username: usernameCandidate } = useAppSelector(selectCandidateData);
  const { username: usernameAdmin, roles } = useAppSelector(selectUserData);

  const [putData, { error, isLoading, isSuccess }] =
    useChangePasswordMutation();
  useImperativeHandle(ref, () => ({
    open: () => changePasword.current?.openModal(),
    resetField: () => {
      form.resetFields();
      if (type === "ADMIN" && usernameAdmin) {
        // if (!roles.includes(ROLE.ROLE_ADMIN)) {
          return form.setFieldValue("username", usernameAdmin);
        // }
      }
      if (type === "USER" && usernameCandidate) {
        return form.setFieldValue("username", usernameCandidate);
      }
    },
  }));

  const handleChangePassword = async () => {
    try {
      const value = await form.validateFields();
      putData(value);
    } catch (error) {
      return;
    }
  };
  const [form] = Form.useForm();
  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      handleChangePassword();
    }
  };

  useEffect(() => {
    const err = error as CustomError;
    if (err) {
      Notification({
        status: "error",
        message: err?.data ?? "Đổi mật khẩu thất bại",
      });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      Notification({
        status: "success",
        message: "Đổi mật khẩu thành công",
      });
      changePasword.current?.closeModal();
    }
  }, [isSuccess]);

  return (
    <div className="login-candidate" onKeyDown={(e) => handleKeyDown(e)}>
      <ModalCustom
        ref={changePasword}
        onOk={handleChangePassword}
        title={"CẬP NHẬT MẬT KHẨU"}
        okText="Cập nhật"
        cancelText="HUỶ BỎ"
        loading={isLoading}
        width={"50%"}
      >
        <Space
          direction="vertical"
          wrap
          style={{ width: "100%" }}
          size={"large"}
        >
          <Form form={form}>
            <Row gutter={[16, 8]}>
              <InputFields data={changePasswordFields} />
            </Row>
          </Form>
        </Space>
      </ModalCustom>
    </div>
  );
});

export default ModalChagnePassword;
