import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {API_METHOD} from "types/api-method";
import { baseQueryCandicateWithReauth } from "types/api/base-query-candidate";
import {FileProps} from "types/api/file";

const {POST} = API_METHOD;

export const FILE_API_REDUCER_KEY = "fileCandidateApi";

export const fileCandidateApi = createApi({
  reducerPath: FILE_API_REDUCER_KEY,
  baseQuery: baseQueryCandicateWithReauth,
  endpoints: builder => ({
    postFileCandidate: builder.mutation<FileProps, any>({
      query: data => ({
        url: apiPath.POST_FILE,
        method: POST,
        data,
      }),
    }),

    
  }),
});

export const fileCandidateApiReducer = fileCandidateApi.reducer;

export const {
  usePostFileCandidateMutation,
} = fileCandidateApi;
