import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { API_METHOD } from "types/api-method";

import { baseQuery, baseQueryWithReauth } from "types/api/base-query";

const { POST } = API_METHOD;

export const FORGOT_PASSWORD_API_REDUCER_KEY = "forgotPasswordAdminApi";

export const forgotPasswordAdminAPI = createApi({
  reducerPath: FORGOT_PASSWORD_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    forgotPasswordAdmin: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: apiPath.FORGOT_PASSWORD,
          method: POST,
          data,
        };
      },
    }),
  }),
});

export const forgotPasswordAdminAPIReducer = forgotPasswordAdminAPI.reducer;

export const { useForgotPasswordAdminMutation } = forgotPasswordAdminAPI;
