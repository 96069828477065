import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ROUTER_ROUTE } from "routers/routers";
import { courseApi } from "store/api/course";
import { DatumCourse } from "types/api/course/get-course";
import { BreadCrumb } from "types/breadCrumb";

export interface GlobalState {
  number: number;
  siderKey: string;
  breadCrumb: Array<BreadCrumb>;
  collapsed: boolean;
  setIsModalOpenRedux: boolean;
  isModalOpen: boolean;
  topicName: string;
  examName: string;
  listIdPart: React.Key[];
  idExamCandidate: number | null | undefined;
  idCourseCandidate: number | string | null;
  dataCourse: DatumCourse[] | null;
  typeLogin: "REGISTER" | "LOGIN";
  typeTests: { value: string; label: string }[] | undefined;
}

const initialState: GlobalState = {
  number: 1,
  breadCrumb: [
    {
      label: "Đề",
      path: ROUTER_ROUTE.EXAM,
    },
  ],
  siderKey: "",
  collapsed: false,
  setIsModalOpenRedux: false,
  isModalOpen: false,
  topicName: "",
  examName: "",
  listIdPart: [],
  idExamCandidate: null,
  idCourseCandidate: null,
  dataCourse: null,
  typeLogin: "REGISTER",
  typeTests: undefined,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setNumber: (state: GlobalState, action: PayloadAction<number>) => {
      state.number = action.payload;
    },
    setBreadCrumb: (
      state: GlobalState,
      action: PayloadAction<BreadCrumb[]>
    ) => {
      state.breadCrumb = action.payload;
    },
    setSiderKey: (state = initialState, actions: PayloadAction<any>) => {
      return {
        ...state,
        siderKey: actions.payload,
      };
    },
    setCollapsed: (state = initialState, action: PayloadAction<any>) => {
      return { ...state, collapsed: action.payload };
    },
    setIsModalOpenRedux: (
      state = initialState,
      actions: PayloadAction<any>
    ) => {
      return {
        ...state,
        isModalOpen: actions.payload,
      };
    },
    setTopicName: (state = initialState, actions: PayloadAction<any>) => {
      return {
        ...state,
        topicName: actions.payload,
      };
    },
    setExamName: (state = initialState, actions: PayloadAction<any>) => {
      return {
        ...state,
        examName: actions.payload,
      };
    },
    setListIdPart: (state = initialState, actions: PayloadAction<any>) => {
      return {
        ...state,
        listIdPart: actions.payload,
      };
    },
    setIdExamCandidate: (
      state: GlobalState,
      action: PayloadAction<number | null | undefined>
    ) => {
      state.idExamCandidate = action.payload;
    },
    setIdCourseCandidate: (
      state: GlobalState,
      action: PayloadAction<number | string | null>
    ) => {
      state.idCourseCandidate = action.payload;
    },
    setTypeLogin: (
      state: GlobalState,
      action: PayloadAction<"REGISTER" | "LOGIN">
    ) => {
      state.typeLogin = action.payload;
    },
    setTypeTests: (
      state: GlobalState,
      action: PayloadAction<{ value: string; label: string }[] | undefined>
    ) => {
      state.typeTests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      courseApi.endpoints.getCourse.matchFulfilled,
      (state, action) => {
        state.dataCourse = action.payload.data;
      }
    );
  },
});

export const {
  setNumber,
  setBreadCrumb,
  setSiderKey,
  setCollapsed,
  setIsModalOpenRedux,
  setExamName,
  setTopicName,
  setListIdPart,
  setIdExamCandidate,
  setIdCourseCandidate,
  setTypeLogin,
  setTypeTests,
} = globalSlice.actions;

export default globalSlice.reducer;
