import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import { baseQueryWithReauth} from "types/api/base-query";
import {
  GetQuestionProps,
  QuestionProps,
  ResponseQuestionProps,
} from "types/api/question";

const {POST, PUT, DELETE} = API_METHOD;

export const QUESTION_API_REDUCER_KEY = "questionApi";

export const questionApi = createApi({
  reducerPath: QUESTION_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Post"],
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    postQuestion: builder.mutation<any, any>({
      query: data => ({
        url: apiPath.POST_QUESTION,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    putQuestion: builder.mutation<QuestionProps, any>({
      query: data => ({
        url: apiPath.PUT_QUESTION,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    deleleQuestion: builder.mutation<ResponseQuestionProps, any>({
      query: id => ({
        url: `${apiPath.DELETE_QUESTION}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
    getDetailQuestionById: builder.query<any, number | string | null>({
      query: id => ({
        url: `${apiPath.GET_DETAIL_BY_ID}?id=${id}`,
      }),
    }),
    getListQuestionByParams: builder.query<
      ResponseQuestionProps,
      GetQuestionProps
    >({
      query: data => ({
        url: `${apiPath.GET_LIST_QUESTION}${serialize(data)}`,
      }),
      providesTags: ["Post"],
    }),
    // getAllQuestionByParams: builder.query<
    //   AllQuestionResponse,
    //   GetAllQuestionProps
    // >({
    //   query: data => ({
    //     url: `${apiPath.GET_ALL_QUESTION}?${toQueryString(data)}`,
    //   }),
    //   providesTags: ["Post"],
    // }),
  }),
});

export const questionApiReducer = questionApi.reducer;

export const {
  usePostQuestionMutation,
  useGetListQuestionByParamsQuery,
  usePutQuestionMutation,
  useGetDetailQuestionByIdQuery,
  useDeleleQuestionMutation,
  // useGetAllQuestionByParamsQuery,
} = questionApi;
