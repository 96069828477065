import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {API_METHOD} from "types/api-method";

import {
  baseQueryCandicate,
  baseQueryCandicateWithReauth,
} from "types/api/base-query-candidate";

const {PUT} = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "changePasswordApi";

export const changePasswordAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery: baseQueryCandicateWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    changePassword: builder.mutation<any, any>({
      query: data => {
        return {
          url: apiPath.CHANGE_PASSWORD,
          method: PUT,
          data,
        };
      },
    }),
  }),
});

export const changePasswordAPIReducer = changePasswordAPI.reducer;

export const {useChangePasswordMutation} = changePasswordAPI;
