import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import {baseQuery, baseQueryWithReauth} from "types/api/base-query";
import {
  DetailResultQuestionProps,
  DetailResultQuestionResponse,
} from "types/api/detail-result-question";

const {GET} = API_METHOD;

export const FILE_API_REDUCER_KEY = "detailResultQuestionApi";

export const detailResultQuestionApi = createApi({
  reducerPath: FILE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Post"],
  endpoints: builder => ({
    detailResult: builder.query<
      DetailResultQuestionResponse,
      DetailResultQuestionProps
    >({
      query: data => ({
        url: `${apiPath.DETAIL_RESULT_QUESTION}${serialize(data)}`,
        method: GET,
      }),
    }),
  }),
});

export const detailResultQuestionReducer = detailResultQuestionApi.reducer;

export const {useDetailResultQuery} = detailResultQuestionApi;
