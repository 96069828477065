import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { API_METHOD } from "types/api-method";

import { baseQuery, baseQueryWithReauth } from "types/api/base-query";

const { PUT } = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "changePasswordAdminApi";

export const changePasswordAdminAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    changePasswordAdmin: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: apiPath.CHANGE_PASSWORD,
          method: PUT,
          data,
        };
      },
    }),
  }),
});

export const changePasswordAdminAPIReducer = changePasswordAdminAPI.reducer;

export const { useChangePasswordAdminMutation } = changePasswordAdminAPI;
