import { FIELD_TYPE } from "types/enums/field-type";
import { DataFormField } from "types/models/input-field-type";
const { INPUT, SELECT, INPUT_PASSWORD, AUTO_COMPLETE } = FIELD_TYPE;

export const loginForm: DataFormField[] = [
  {
    name: "email",
    type: INPUT,
    col: 1,
    label: "Email/SĐT",

    rules: [
      // {
      //   type: "email",
      //   message: "Email không hợp lệ!",
      // },
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
  },
  {
    name: "password",
    type: INPUT_PASSWORD,
    col: 1,
    label: "Mật khẩu",
    rules: [
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
  },
];

export const registerForm: DataFormField[] = [
  {
    name: "fullName",
    type: INPUT,
    label: "Họ tên",
    col: 1,
    rules: [
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
  },
  {
    name: "phoneNumber",
    type: INPUT,
    col: 1,
    label: "Số điện thoại",

    rules: [
      {
        required: true,
        validator: (_, value) => {
          const phoneNumberRegex = /^(0\d{9}|\+84\d{9})$/;
          if (!value) {
            return Promise.reject(new Error("Bắt buộc!"));
          } else if (value.includes(+84) && value.length >= 13) {
            return Promise.reject(new Error("Số điện thoại sai định dạng!"));
          } else if (value.length > 10) {
            return Promise.reject(
              new Error("Số điện thoại phải nhỏ hơn 10 số")
            );
          } else if (!phoneNumberRegex.test(value)) {
            return Promise.reject(
              new Error("Số điện thoại phải bắt đầu từ 0 hoặc +84.")
            );
          }
          return Promise.resolve();
        },
        whitespace: true,
      },
    ],
  },
  {
    name: "otherPhoneNumber",
    type: INPUT,
    col: 1,
    label: "Số điện thoại khác",
  },
  {
    name: "email",
    type: INPUT,
    col: 1,
    label: "Email",
    rules: [
      {
        type: "email",
        message: "Email không hợp lệ!",
      },
    ],
  },
  {
    name: "target",
    type: AUTO_COMPLETE,
    col: 1,
    label: "Mục tiêu",
    options: [
      { value: "Toeic Nghe - Đọc (LR)" },
      { value: "Toeic Nói - Viết (SW)" },
      { value: "Toeic 4 kỹ năng (LR - SW)" },
      { value: "Ielts" },
      { value: "Giao tiếp" },
      { value: "B1,B2" },
      { value: "Cấp 2, cấp 3, ôn thi THPT" },
    ],
    rules: [
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
  },
  {
    name: "typeTest",
    type: SELECT,
    col: 1,
    label: "Lựa chọn bài test",
    // options: [
    //   { value: "Test đầu vào", label: "Test đầu vào" },
    //   { value: "Toeic LR", label: "Toeic LR" },
    //   { value: "Toeic SW", label: "Toeic SW" },
    //   { value: "Ielts", label: "Ielts" },
    //   { value: "Giao tiếp", label: "Giao tiếp" },
    //   { value: "B1,B2", label: "B1,B2" },
    // ],
    rules: [
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
  },
  {
    name: "studiedAtSec",
    type: SELECT,
    col: 1,
    label: "Đã học tại SEC chưa?",
    rules: [
      {
        required: true,
        message: `Bắt buộc!`,
      },
    ],
    options: [
      { label: "Đã học tại SEC", value: true },
      { label: "Chưa học tại SEC", value: false },
    ],
  },
];
